<template>
  <div class="contactus">
    <div class="mainpic">
      <div class="text">
        <h4>黑洞图形引擎</h4>
        <p>拥有150多种接口满足您的不同需求</p>
      </div>
    </div>
    <div class="info">
      <div class="container">
        <div class="tit">联系我们</div>
        <!-- 表单提交 -->
        <div class="forminfo container">
          <el-form :model="form" :rules="rules" ref="form" label-width="0px" class="demo-ruleForm">
            <el-form-item>个人</el-form-item>
            <el-form-item label="" prop="name">
              <el-input v-model="form.name" placeholder="称呼"></el-input>
            </el-form-item>
             <el-form-item label="" prop="phone">
              <el-input v-model="form.phone" placeholder="电话"></el-input>
            </el-form-item>
             <el-form-item label="" prop="email">
              <el-input v-model="form.email" placeholder="邮箱"></el-input>
            </el-form-item>
            <!-- <el-form-item label="" prop="region">
                <el-cascader
                  size="large"
                  :popper-append-to-body="false"
                  :options="options"
                  v-model="form.region"
                  @change="handleChange">
                </el-cascader>
            </el-form-item> -->
             <el-form-item label="" prop="job">
              <el-input v-model="form.job"  placeholder="行业"></el-input>
            </el-form-item>
            <el-form-item>公司</el-form-item>
            <el-form-item label="" prop="corporationTeam">
              <el-input v-model="form.corporationTeam"  placeholder="公司/团队名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="corporationPosition">
              <el-input v-model="form.corporationPosition"  placeholder="职务/职位"></el-input>
            </el-form-item>
            <el-form-item>项目</el-form-item>
            <el-form-item label="" prop="itemDes">
              <el-input type="textarea" v-model="form.itemDes"  placeholder="项目描述" minlength="8" maxlength="300"></el-input>
            </el-form-item>
            <el-form-item label="" prop="form_checked">
              <el-checkbox v-model="form.checked">我希望销售成员联系我并洽谈关于购买定制许可事宜。</el-checkbox>
            </el-form-item>
            <el-form-item>
              <div class="mybutton">
                <span @click="submit">提交</span>
                <span @click="reset">重置</span>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {regionData,CodeToText} from 'element-china-area-data'
import '../../assets/css/cascader.css'
export default {
  data () {
    return {
      form:{
        name: '',
        phone: '',
        email: '',
        job: '',
        corporationTeam: '',
        corporationPosition: '',
        itemDes: '',
        checked: false
      },
      rules: {
        name: [
          { required: true, message: '请输入称呼', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: ['blur','change'] }
        ],
        corporationTeam: [
          { required: true, message: '请输入公司/团队名', trigger: 'blur' }
        ],
        corporationPosition: [
          { required: true, message: '请输入职位/职务', trigger: 'blur' }
        ],
        itemDes: [
          { min: 8, max: 300, message: '最输入8-300字', trigger: 'blur' }
        ]
      },
      options: regionData,
      selectedOptions: [],
      addtions:{},
      checked: false
    }
  },
  methods: {
    callus () {
      this.isdownloadinfo = false
    },
    handleChange (value) {
      //我们选择地址后，selectedOptions 会保存对应的区域码，例如北京的区域码为'110000'
      //我们要取出区域码对应的汉字，就需要用CodeToText(区域码)进行输出
      this.addtions.selectedOptions = value
      var name = ''
      this.selectedOptions.map(item => name += CodeToText[item] + '/')
      this.addtions.names = name
      // console.log(this.addtions.names)
      // console.log(this.addtions)
    },
    reset () {
      this.$refs.form.resetFields();
    },
    async submit () {
      try{
         await this.$refs.form.validate()
         if (!this.form.checked) {
           this.$message.error('请勾选许可事宜')
           return false
         }
         const {data} = await this.$axios.post('contact/create', this.form)
         if (data.code === 200) {
           this.$message.success('提交成功')
           this.$refs.form.resetFields()
           this.form.checked = false
         }else {
           console.log('1')
           return this.$message.error('提交失败')
         }
      } catch {
        return false
      }
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
a{
  color: #fff;
}
.contactus{
  color: #fff;
  .mainpic{
    width: 100%;
    height: 50vh;
    min-width: 400px;
    background: url('../../assets/image/download/main.png') center center no-repeat;
    background-size: cover;
    position: relative;
    .text{
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      h4{
        font-size: 50px;
        // font-weight: normal;
        font-family: "siyuan";
        @media screen and (max-width:768px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      p{
        font-size: 28px;
        line-height: 60px;
        @media screen and (max-width:768px) {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
  .info{
    width: 100%;
    // height: 70vh;
    background: url('../../assets/image/download/bg.jpg') center center no-repeat;
    background-size: cover;
    padding-bottom: 60px;
    .tit{
      font-size: 30px;
      line-height: 120px;
      text-align: center;
    }
    .downloadinfo{
      .cardlist{
        width: 100%;
        overflow: hidden;
        .card{
          width: calc(50% - 20px);
          height: 250px;
          background-color: #fff;
          float: left;
          margin-right: 40px;
          border-radius: 5px;
          color: #000;
          padding: 20px 40px;
          text-align: center;
          overflow: hidden;
          position: relative;
          @media screen and (max-width:768px) {
            width: 80%;
            float: none;
            margin: 30px auto;
          }
          h3{
            // line-height: 50px;
            margin: 20px 0;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          p{
            margin-top: 30px;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          span{
            width: 120px;
            height: 40px;
            display: block;
            border-radius: 5px;
            color: #fff;
            background-color: #0aaff1;
            line-height: 40px;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
          }
        }
        .card:last-child{
          margin: 0;
          @media screen and (max-width:768px) {
            margin: 30px auto;
          }
        }
      }
      .more{
        text-align: center;
        margin-top: 60px;
        p:first-child{
          font-size: 18px;
          margin-bottom: 5px;
        }
        span{
          display: block;
          width: 120px;
          margin: 0 auto;
          margin-top: 50px;
          padding: 5px 20px;
          color: #0aaff1;
          border: 1px solid #0aaff1;
          cursor: pointer;
        }
      }
    }
    .forminfo{
      /deep/ .el-form{
        padding: 20px 30px;
        width: 40%;
        margin: 0 auto;
        background-color: #232323;
        border-radius: 5px;
        border: 1px solid #666666;
        overflow: hidden;
        color: #fff;
        @media screen and (max-width:768px) {
          width: 90%;
        }
        @media (min-width:768px) and (max-width:992px) {
          width: 60%;
        }
        .el-input__inner,
        .el-textarea__inner,
        .el-select-dropdown,
        .el-select-dropdown__item .hover,
        .el-cascader__dropdown{
          background-color: #232426;
          color: #fff;
        }
        .el-select,
        .el-cascader{
          width: 100%;
        }
        .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
          background-color: #232323;
        }
        .el-select-dropdown__item{
          color: #fff;
        }
        .el-checkbox{
          color: #c0c4cc;
        }
        .el-form-item__content{
          font-size: 16px;
        }
        .el-checkbox__label{
          @media screen and (max-width:768px) {
            font-size: 12px;
          }
        }
        .mybutton{
          span{
            width: 150px;
            display: inline-block;
            text-align: center;
            height: 40px;
            line-height: 40px;
            border-radius: 5px;
            cursor: pointer;
          }
          span:first-child{
            background-color: #0aaff1;
            margin-right: 30px;
            @media screen and (max-width:768px) {
              margin-right: 10px;
            }
          }
          span:last-child{
            background-color: #000000;
            border: 1px solid #666666;
          }
        }
      }
    }
  }
}
</style>
